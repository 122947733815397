import React from "react"
import { Button, Modal } from "react-bootstrap"
import { t } from "i18next"
import { observer } from "mobx-react-lite"
import { useAuthStore } from "../../stores/StoreProvider"
import { sanitize } from "../../formatters/Sanitize"

export function Notification(props: {skip: boolean}) {
  const authStore = useAuthStore()
  const show = !props.skip && !!authStore.domain?.notification && !authStore.notificationProcessed

  const handleContinue = () => {
    authStore.setNotificationProcessed()
  }

  return (
    <Modal backdrop="static" show={show} centered>
      <Modal.Body>
        <div dangerouslySetInnerHTML={{ __html: sanitize(authStore.domain?.notification ?? "") }} />
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleContinue}>{t("shared.button.continue")}</Button>
      </Modal.Footer>
    </Modal>)
}

export default observer(Notification)
